import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {KmService} from '../km.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'km-area-detail',
  templateUrl: './area-detail.component.html',
  styleUrls: ['./area-detail.component.css']
})
export class AreaDetailComponent implements OnInit {

  constructor(
    private aroute: ActivatedRoute,
    private service: KmService
    ) { }

  idArea: any;
  area: any = [];
  form: FormGroup;

  ngOnInit(): void {
    this.idArea = this.aroute.snapshot.params.id;
    this.getAreaById(this.idArea);
    this.form = new FormGroup({
      area: new FormControl('', Validators.required),
      description: new FormControl(this.area.description, Validators.required),
    });
  }

  getAreaById(id: any): void {
    this.service.getAreaById(id).subscribe(
      area => {
        this.area = area;
        this.form.patchValue({
          area: this.area.area,
          description: this.area.description
        });
      }, error => {
        console.log(error);
      }
    );
  }

  async update(): Promise<void> {
    try{
      const res = await this.service.activateArea(this.form.value, this.idArea);
      console.log(res);
      this.getAreaById(this.idArea);
    }catch (e){
      console.log(e);
    }
  }

  submit(): void {
    console.log(this.form.value);
    this.update();
  }
}
