import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'km-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  constructor() { }

  @Input() overlay: boolean = true;

  ngOnInit(): void {
  }

  showOverlay(){
    this.overlay = !this.overlay;
  }

}
