import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KmService } from '../km.service';
import { NotifyService } from '../notify.service';

@Component({
  selector: 'km-conhecimento-form',
  templateUrl: './conhecimento-form.component.html',
  styleUrls: ['./conhecimento-form.component.css']
})
export class ConhecimentoFormComponent implements OnInit {

  constructor(
    private service: KmService,
    private notify: NotifyService
    ) { }

  @Input() id: number;
  @Output() success = new EventEmitter<boolean>();
  form: FormGroup;

  ngOnInit(): void {
    this.form = new FormGroup({
      knowledge: new FormControl('', Validators.required),
      idSubject : new FormControl(this.id, Validators.required),
    });
  }

  async addKnowledge(){
    try{
      const res = await this.service.addConhecimento(this.form.value);
      console.log(res);
      this.success.emit(true);
      this.notify.openSnackBar("Foi!", 3000);
    }catch(e){
      console.log(e);
      this.success.emit(false);
      this.notify.openSnackBar("Lascou!", 3000);
    }
  }

}
