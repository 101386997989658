 <article class="container mat-app-background">
<!--<article class="container">-->
<mat-toolbar color="primary">
    <mat-slide-toggle [(ngModel)]="isDark" (change)="switch()" ></mat-slide-toggle>
    <a mat-button [routerLink]="['/']" > <mat-icon>home</mat-icon> </a>
    <a mat-button [routerLink]="['/management']" >Gerenciar Áreas</a>
</mat-toolbar>


  <main>
    <router-outlet></router-outlet>
  </main>

<!--  <km-nav-footer></km-nav-footer>-->
</article>



