import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private login: LoginService, 
    private router: Router
    ){}
    
    // isLogged = window.localStorage.getItem('kmAuth');
    
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      console.log(this.login.getJws());
      if(this.login.isUser()){
        return true;
      }
        
        this.router.navigate(['/login']);
        return false;      

  }
}