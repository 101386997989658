import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private login: LoginService) {}
  
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const jwt = this.login.getJws();
    console.log(request.url);

    if(request.url.includes('user')){
      return next.handle(request);
    }else if (jwt !== null){
      request = request.clone({
          setHeaders: {
              Authorization: 'Bearer '+ window.localStorage.getItem('kmAuth')
          }
      });
  }
  
    return next.handle(request);
    
  }
}
