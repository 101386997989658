<!-- <km-modal [overlay]=""></km-modal> -->
<!-- <km-loader [isLoading]="loader"></km-loader> -->
<!-- <div *ngFor="let a of array"> {{a.area}} </div> -->

<mat-progress-bar mode="indeterminate" *ngIf="loader"></mat-progress-bar>

<section>
  <mat-toolbar color="accent" style="height: 35px;">
    <button mat-icon-button (click)="showAreaForm()">
      <mat-icon> {{areaForm ? 'remove' : 'add'}} </mat-icon>
    </button>
    <button mat-icon-button (click)="get()">
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-toolbar>

  <!-- Cadastrar nova área -->
  <div *ngIf="areaForm">
    <km-area-form (success)="addArea($event)"></km-area-form>
  </div>

  <!-- Cadastrar novo assunto -->
  <div *ngIf="assuntoForm">
    <km-assunto-form [id]="assuntoId" (success)="addAssunto($event)"></km-assunto-form>
  </div>

  <!-- Lista de áreas -->
  <div *ngFor="let area of areas;let index = index">

    <div #el id="{{area.id}}"  *ngIf="areasVisible">
      <mat-card class="area-item" >
        <button mat-icon-button (click)="showAssunto(index, el.id, $event )">
          <mat-icon id="{{area.id}}" #icons> chevron_right </mat-icon>
        </button> {{area.area}} ({{area.registers}})

        <button mat-icon-button (click)="showAssuntoForm(area.id)" style="margin-left: auto;">
          <mat-icon>add</mat-icon>
        </button>
      </mat-card>
    </div>

      <div>
        <km-assuntos [idArea]="area.id" *ngIf="visibleIndices.has(index)"></km-assuntos>
      </div>

  </div>
</section>

<!-- <section *ngIf="showAssuntos">
  <button (click)="showHide()">Voltar</button>
  <div >
    <km-assuntos [idArea]="idArea"></km-assuntos>
  </div>
</section> -->

<!--<ul>-->
<!--  <li *ngFor="let area of areas">-->
<!--    {{area.area}}"-->
<!--    <button (click)="updateArea(area.pk_area)">UP</button>-->
<!--  </li>-->
<!--</ul>-->

<!--<div >-->
<!--  <ul>-->
<!--    <li *ngFor="let area of areas">-->
<!--      <div class="lista">-->
<!--        <button class="fas fa-chevron-right bg"-->
<!--                (click)="changeSingleArrow(el.id , $event); "-->
<!--                #el id="{{area.pk_area}}"-->
<!--        >-->
<!--        </button>-->

<!--        <div>{{area.area}}</div>-->
<!--      </div>-->

<!--      <div id="{{'area'+area.pk_area}}" class="assuntos">-->
<!--        <ul>-->
<!--          <li></li>-->
<!--        </ul>-->
<!--      </div>-->

<!--    </li>-->
<!--  </ul>-->
<!--</div>-->

<!--<div style="margin-top: 15px">-->
<!--  <button (click)="limparAreas()">Limpar</button>-->
<!--</div>-->
