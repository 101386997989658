import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient
    ) { }

  // login = `${environment.API}authentication`;
  login = `${environment.API}user`;

  async entrar(form: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.login, form, { headers, observe: 'response'}).toPromise();
  }
  async renovar() {
    let jwt = window.localStorage.getItem('kmAuth');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.login+"/"+jwt, { headers, observe: 'response'}).toPromise();
  }

  async renew(){
    try{
      const res = await this.renovar();
      window.localStorage.clear();
      window.localStorage.setItem('kmAuth',res['token']);
      console.log(res['token']);
    }catch(e){
      console.log('Foi não', e)
    }
    
  }

  getJws(): string{
    try {
      const jwt: any = jwt_decode(window.localStorage.getItem('kmAuth'));
      if (jwt !== 'undefined') {
        return jwt;
      }
    }catch (error) {
     return null;
    }
  }

  isUser(): boolean{

    if(this.getJws() != null){
        return true;
      }else{
        return false;
      }
  }
    
}
