import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AreasComponent} from './areas/areas.component';
import {SettingsComponent} from './settings/settings.component';
import {AreaDetailComponent} from './area-detail/area-detail.component';
import { AreaManagementComponent } from './area-management/area-management.component';
import { PnfComponent } from './pnf/pnf.component';
import { RegistroComponent } from './registro/registro.component';
import { PerformanceTestComponent } from './performance-test/performance-test.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
    {path: 'login', component: LoginFormComponent},
    {path: '', component: AreasComponent, canActivate: [AuthGuard]},
    {path: 'settings', component: SettingsComponent, canActivate: [AuthGuard]},
    {path: 'management', component: AreaManagementComponent, canActivate: [AuthGuard]},
    {path: 'register/:id', component: RegistroComponent, canActivate: [AuthGuard]},
    {path: 'area-detalhe/:id', component: AreaDetailComponent, canActivate: [AuthGuard]},
    {path: '**', component: PnfComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
