import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { KmService } from '../km.service';

@Component({
  selector: 'km-conhecimento',
  templateUrl: './conhecimento.component.html',
  styleUrls: ['./conhecimento.component.css']
})
export class ConhecimentoComponent implements OnInit {

  constructor(
    private service: KmService,
    private dataService: DataService
    ) { }

  @Input() id: string;
  conhecimento: any = [];
  idConhecimento: string;
  registro: boolean;
  titulo: string;

  ngOnInit(): void {
    this.getConhecimento();
  }

  showRegistro(){
    this.registro = !this.registro;
    if (this.registro){
      this.dataService.changeVisibility(false);
    }else{
      this.dataService.changeVisibility(true);
    }
  }

  hideRegistro(e: boolean){
    this.registro = e;
  }

  getRegistro(id: string, conhecimento: string): void {
    this.idConhecimento = id;
    this.titulo = conhecimento;
    // console.log(this.idConhecimento, id)
    this.showRegistro();
  }

  getConhecimento(){
    this.service.getConhecimentoById(this.id).subscribe(
      know => {
        this.conhecimento = know;
      }, e =>{
        console.log(e);
      }
    );
  }
}
