import {Component, HostBinding, Inject, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { Event , NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import {SwPush} from '@angular/service-worker';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {KmService} from './km.service';


@Component({
  selector: 'km-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  // constructor(
  //   private swPush: SwPush,
  //   private service: KmService,
  //   private router: Router
  //   ) {}
  loading = true;
  msg: string;
  public isDark: boolean;

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {
    // console.log('Contructed');
    this.loading = true;

  }

  ngOnInit(): void {
    this.getTheme();
    this.loading = true;
    this.msg = 'Navegando...';
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          this.msg = 'Navegando...';
          // console.log(this.loading);
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          this.msg = 'Acabou...';
          // console.log(this.loading);
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  switch(): void{
    const hostClass = this.isDark ? 'dark' : 'light';
    this.renderer.setAttribute(this.document.body, 'class', hostClass);
    this.renderer.setAttribute(this.document.body, 'data-theme', hostClass);
    if (this.isDark){
      window.localStorage.setItem('theme', 'dark');
    }else {
      window.localStorage.setItem('theme', 'light');
    }
  }

  loader(): void{
    this.loading = !this.loading;
  }

  getTheme(): void{
    let theme = window.localStorage.getItem('theme');
    //console.log(theme);
    switch (theme){
      case 'light':
        this.isDark = false;
        break;

      case null:
        this.isDark = false;
        theme = 'light';
        break;

      case 'dark':
        this.isDark = true;
        break;

      default:
        this.isDark = false;
    }
    const hostClass = theme;
    this.renderer.setAttribute(this.document.body, 'class', hostClass);
    this.renderer.setAttribute(this.document.body, 'data-theme', hostClass);
  }

}
