import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KmService } from '../km.service';

@Component({
  selector: 'km-assunto-form',
  templateUrl: './assunto-form.component.html',
  styleUrls: ['./assunto-form.component.css']
})
export class AssuntoFormComponent implements OnInit {

  constructor(private service: KmService) { }

  @Input() id: number;
  @Output() success = new EventEmitter<boolean>();
  form: FormGroup;

  ngOnInit(): void {
    this.form = new FormGroup({
      subject: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      idArea: new FormControl(this.id, Validators.required),
    });
  }

  async addAssunto(){
    console.log(this.form.value);
    try{
      const res = await this.service.addAssunto(this.form.value);
      console.log(res);
      this.success.emit(true);
    }catch(e){
      console.log(e);
      this.success.emit(false);
    }
  }

}
