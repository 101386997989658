import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'km-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  constructor() { }

  @Input() isLoading: boolean = false;

  ngOnInit(): void {
  }

}
