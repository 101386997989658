
<div *ngFor="let item of unico; let index = index">
  <mat-card color="accent" class="assunto-item" *ngIf="assuntoVisible">
    <button mat-icon-button (click)="showConhecimento(index); getIcon(item.id)">
      <mat-icon id="{{item.id}}" #icons>chevron_right</mat-icon>
    </button> {{item.subject}}
    
    <button mat-icon-button style="margin-left: auto;" (click)="showFormConhecimento(item.id);">
      <mat-icon color="accent">add</mat-icon>
    </button>
  </mat-card>
    <!-- {{item.description}} <br> -->
  <div style="padding: 3px;">
    <km-conhecimento [id]="item.id" *ngIf="visibleIndices.has(index)"></km-conhecimento>
  </div>

</div>

<div *ngIf="showForm">
  <km-conhecimento-form [id]="idSubject" (success)="addConhecimento($event)"></km-conhecimento-form>
</div>

