import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { KmService } from '../km.service';
import { NotifyService } from '../notify.service';

@Component({
  selector: 'km-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {

  constructor(
    private service: KmService,
    private notify: NotifyService,
    private dataService: DataService
    ) { }

  @ViewChildren('content') divs: QueryList<ElementRef>;
  @ViewChild('conteudo') private conteudo: ElementRef;
  @ViewChild('idRegistro') public idRegistro: ElementRef;
  registro: any = [];
  @Input() idConhecimento: string;
  @Input() conhecimento: string;
  @Output() visible = new EventEmitter<boolean>();
  content: string;
  isRegistro: boolean;

  ngOnInit(): void {
    // this.idConhecimento = this.aroute.snapshot.params.id;
    this.getRegistro();
  }

  visibility(): void {
    this.visible.emit(false);
    this.dataService.changeVisibility(true);
  }

  async addRegistro(conteudo: any): Promise<void> {
    let body: any = [];
    body.push({idKnowledge: this.idConhecimento, content: conteudo.innerHTML});

    body = JSON.stringify(body).replace('[', '').replace(']', '');

    try{
      const res = await this.service.addRegistro(body);
      this.notify.openSnackBar('Foi!', 3000);
      console.log(res);
      this.getRegistro();
    }catch (e){
      this.notify.openSnackBar('Lascou!', 3000);
      console.log(e);

    }
  }

  getRegistro(): void {
    this.service.getRegistroById(this.idConhecimento).subscribe(
      register => {
        this.registro = register;
        this.isRegistro = true;
      }, e => {
        console.log(e);
        // this.registro.push({id: 0, content: 'Nada por aqui ainda...'});
        this.isRegistro = false;
      }
    );
  }

  async atualizar(id: any): Promise<void> {

    let body: any = [];
    this.divs.forEach(e => {
      if (id == e.nativeElement.id){
        // console.log(e.nativeElement.innerHTML);
        body.push({content: e.nativeElement.innerHTML});
      }
    });

    body = JSON.stringify(body).replace('[', '').replace(']', '');
    console.log(id, body);
    try{
      const res = this.service.updateRegistro(id, body);
      this.notify.openSnackBar('Foi!', 3000);
      console.log(res);
    }catch (e){
      this.notify.openSnackBar('Lascou!', 3000);
      console.log(e);
    }
  }

}
