<km-sidenav [visibility]="toogledMenu" (hide)="toogleMenu()"></km-sidenav>
<nav>
  <i class="fas fa-bars fa-lg button" (click)="toogleMenu()" style="margin-left: auto"></i>

<!--  <i class="fas fa-times fa-lg" style="margin-left: 5px" *ngIf="toogledMenu" (click)="toogleMenu()"></i>-->
<!--  <ul>-->
<!--    <li routerLink="" routerLinkActive="active"><a >Home</a></li>-->
<!--    <li><a>Register</a></li>-->
<!--    <li routerLink="settings" routerLinkActive="active" ><a>Settings</a></li>-->
<!--  </ul>-->
<!--  <div class="logo"><i class="fas fa-brain"></i></div>-->
<!--  <i class="fas fa-user"></i>-->
  </nav>
<span class="separator">&nbsp;</span>
