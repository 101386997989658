<div style="height: 50px; background-color: teal; color: white; display: flex; align-items: center; margin-bottom: 5px;">
    <button>Management</button>
</div>

<!-- <div *ngFor="let a of areas" style="margin-bottom: 20px;">
    {{a.area}}
</div> -->

<div class="container" *ngFor="let a of array; let i = index">

    <div class="areas">
        {{a.area}} 
        <button style="margin-left: auto;">Add</button>        
    </div>

        <div *ngFor="let ass of a.assunto">   

            <div  class="areas-item" style="display: flex; flex-direction: row;"> 
                {{ass == null ? 'Nada' : ass.assunto}} 
            </div>

            <div *ngFor="let con of ass.conhecimento" class="areas-conhecimento" style="display: flex; flex-direction: row;"> 
                {{con == null ? 'Nada' : con.conhecimento}} 
            </div>

        </div>

</div>