import {Component, OnInit, ElementRef, Inject, Renderer2, ViewChildren, QueryList} from '@angular/core';
import {KmService} from '../km.service';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { DataService } from '../data.service';

@Component({
  selector: 'km-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.css']
})
export class AreasComponent implements OnInit {

  constructor(
    private service: KmService,
    private el: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private dataService: DataService
    ) { }

  @ViewChildren('icons') icons: QueryList<MatIcon>;
  areas: any  [];
  assuntos: any  [];
  arrowDown: boolean;
  showAssuntos: boolean;
  idArea: any;
  loader: boolean;

  visibleIndices = new Set<number>();

  areaForm: boolean;
  assuntoForm: boolean;
  assuntoId: number;
  areasVisible: boolean;

  array: any = [];

  ngOnInit(): void {
    this.get();
    this.dataService.visibility.subscribe(
      visible => {
        this.areasVisible = visible;
      }
    );
    // this.getNewProcedureAreas();
  }

  getNewProcedureAreas(): void {
    this.service.getNewAreasList().subscribe(
      a => {
        console.log(a);
        this.array = a;
      }
    );
  }

  showAreaForm(): void{
    this.areaForm = !this.areaForm;
  }

  showAssuntoForm(id: number): void{
    this.assuntoId = id;
    this.assuntoForm = !this.assuntoForm;
  }

  addArea(success: boolean): void{
    if (success){
      this.showAreaForm();
      this.get();
    }
  }

  addAssunto(success: boolean): void{
    if (success){
      this.assuntoForm = false;
    }
  }

  showAssunto(index, el: any, e): void{
    const elClass = e.target.innerText;
    if (!this.visibleIndices.delete(index)) {
      this.visibleIndices.add(index);

    }
    this.getIcon(el);

  }

  getIcon(id: number): void{
    let icon = '';
    this.icons.forEach(e => {
      if (e._elementRef.nativeElement.id === id){
        icon = e._elementRef.nativeElement.innerText;
        if (icon.includes('right')){
            e._elementRef.nativeElement.innerText = 'expand_more';
          }else{
            e._elementRef.nativeElement.innerText = 'chevron_right';
          }
      }
    });
  }

  updateArea(area): void {
    this.router.navigate(['area-detalhe', area]);
  }

  showHide(): void{
    this.showAssuntos = !this.showAssuntos;
  }

  getAssunto(id): void{
    this.idArea = id;
    this.showHide();
    // this.service.getAssuntoById(id).subscribe(
    //   assuntos => {
    //     this.assuntos = assuntos;
    //   }
    // );
  }

  findByKey(idArea: number): void{
    this.assuntos.find(a => {
      console.log(a.subject);
    });
  }

  changeSingleArrow(id: string, e: any): void {
    const elClass = e.target.className;
    this.idArea = id;
    if (elClass.includes('fa-chevron-right')){
      // this.assuntos = [];
      // this.getAssuntos(id);
      this.renderer.removeClass(e.target, 'fa-chevron-right');
      this.renderer.addClass(e.target, 'fa-chevron-down');
      document.getElementById('area' + id).style.display = 'block';
    }else{
      this.renderer.removeClass(e.target, 'fa-chevron-down');
      this.renderer.addClass(e.target, 'fa-chevron-right');
      document.getElementById('area' + id).style.display = 'none';
    }
  }

  changeArrow(): boolean {
    this.arrowDown = !this.arrowDown;
    let arrow: boolean;
    this.arrowDown ? arrow = true : arrow = false;
    return arrow;
  }

  get(): void{
    this.loader = true;
    this.areasVisible = true;
    this.service.getAreas('').subscribe(
      area => {
        this.areas = area;
        this.loader = false;
      }, e => {
        this.loader = false;
      }
    );
  }

  getAssuntosLista(): void {
    this.service.getAssuntos().subscribe(
      assuntos => {
        this.assuntos = assuntos;
      }
    );
  }

  limparAreas(): void {
    this.areas = [];
  }

  getAssuntos(pkArea: any): void {
    this.service.getSubject(pkArea).subscribe(
      assuntos => {
        this.assuntos = assuntos;
      }
    );
  }
}
