<mat-toolbar color="accent" style="height: 30px; margin-top: 10px;">
    <button mat-icon-button (click)="visibility()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <button mat-icon-button (click)="isRegistro = !isRegistro" style="margin-left: auto;">
        <mat-icon>add</mat-icon>
    </button>
</mat-toolbar>

<section>
    
    <mat-toolbar style="height: 30px;" color="primary">
        {{conhecimento}}  
    </mat-toolbar>

    <div *ngFor="let r of registro; let i = index">
        <div class="title" *ngIf="isRegistro">            
            Registro: {{r.createdAt | date:"dd/MM/yyyy hh:mm"}}
            <button mat-icon-button
                style="margin-left: auto; color: white;" 
                (click)="atualizar(r.id)">
                <mat-icon>save</mat-icon>
            </button>                              
        </div>

        <div *ngIf="isRegistro"
            class="div-edit"
            contenteditable="true" 
            [innerHTML]="r.content"
            #content
            id="{{r.id}}"
            >
        </div>        
    </div>

    <div *ngIf="!isRegistro">
        <div class="title">Novo Registro</div>
        <div class="div-edit"
            contenteditable="true" #content></div>
        <button mat-raised-button (click)="addRegistro(content)">Cadastrar</button>
    </div>

</section>