import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'km-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  constructor() { }

  @Input() visibility: boolean;
  @Output() hide = new EventEmitter<boolean>();

  ngOnInit(): void {
  }

  closeSideNav(): void{
    this.hide.emit(false);
    this.visibility = false;
  }

}
