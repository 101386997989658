<section>
    <div class="logo">
        <img src="assets/icons/icon-128x128.png" alt="KM Logo">
    </div>
    <form style="text-align: center;" 
        [formGroup]="form"
        (ngSubmit)="submit()">
        <input type="email" placeholder="Email" autocomplete="on" formControlName="user">
        <input type="password" placeholder="senha" minlength="6" formControlName="password">
        <button [disabled]="!form.valid"[class.disabled]="!form.valid" >Login</button>
    </form>
</section>