import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NavComponent } from './nav/nav.component';
import { AreasComponent } from './areas/areas.component';
import { SettingsComponent } from './settings/settings.component';
import { NavFooterComponent } from './nav-footer/nav-footer.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { AssuntosComponent } from './assuntos/assuntos.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AreaDetailComponent } from './area-detail/area-detail.component';
import { AreaManagementComponent } from './area-management/area-management.component';
import { LoaderComponent } from './loader/loader.component';
import { PnfComponent } from './pnf/pnf.component';
import { ConhecimentoComponent } from './conhecimento/conhecimento.component';
import { RegistroComponent } from './registro/registro.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MaterialModule} from './material/material.module';
import { AreaFormComponent } from './area-form/area-form.component';
import { AssuntoFormComponent } from './assunto-form/assunto-form.component';
import { ModalComponent } from './modal/modal.component';
import { ConhecimentoFormComponent } from './conhecimento-form/conhecimento-form.component';
import { PerformanceTestComponent } from './performance-test/performance-test.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { AuthInterceptor } from './auth.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { LoginService } from './login.service';
import { ApplicationErrorHandler } from './application-error-handler';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    AreasComponent,
    SettingsComponent,
    NavFooterComponent,
    SidenavComponent,
    AssuntosComponent,
    AreaDetailComponent,
    AreaManagementComponent,
    LoaderComponent,
    PnfComponent,
    ConhecimentoComponent,
    RegistroComponent,
    AreaFormComponent,
    AssuntoFormComponent,
    ModalComponent,
    ConhecimentoFormComponent,
    PerformanceTestComponent,
    LoginFormComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MaterialModule
    ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    // {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true, deps: [LoginService]}
    {provide: ErrorHandler, useClass: ApplicationErrorHandler},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
