import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';
import { NotifyService } from '../notify.service';

@Component({
  selector: 'km-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {

  constructor(
    private service: LoginService,
    private notify: NotifyService,
    private router: Router
    ) { }

  form: FormGroup;

  ngOnInit(): void {
    this.form = new FormGroup({
      user: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });
  }

  async submit() {
    window.localStorage.clear();
    try{
      const response = await this.service.entrar(this.form.value);
      console.log(response.body['token']);
      window.localStorage.setItem('kmAuth',response.body['token']);
      if(response.status == 200){
        this.router.navigate(['']);
      }
      this.notify.openSnackBar("Partiu estudar!", 2000);
      console.log(response.status);
      
    }catch(e){
      console.log(e.error);
      this.notify.openSnackBar("Vixi, deu não hein...", 2000);
      window.localStorage.setItem('kmAuth','403');
    }
    
  }

}
