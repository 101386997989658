<div *ngIf="overlay">

    <div class="overlay" (click)="showOverlay()">
        
    </div>

    <mat-card style="z-index: 10; position: absolute; width: 90%; margin: 0 auto; top: 40%">
        Cardão!
    </mat-card>

</div>