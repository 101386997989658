import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KmService } from '../km.service';

@Component({
  selector: 'km-area-form',
  templateUrl: './area-form.component.html',
  styleUrls: ['./area-form.component.css']
})
export class AreaFormComponent implements OnInit {

  constructor(private service: KmService) { }

  form: FormGroup;
  @Output() success = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.form = new FormGroup({
      area: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
    });
  }

  async addArea() {
    try{
      const res = await this.service.addArea(this.form.value);
      console.log(res);
      this.success.emit(true);
    }catch(e){
      console.log(e);
      this.success.emit(false);
    }
  }

}
