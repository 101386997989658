import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KmService } from '../km.service';
import { AreaModel } from '../models/Area';

@Component({
  selector: 'km-area-management',
  templateUrl: './area-management.component.html',
  styleUrls: ['./area-management.component.css']
})
export class AreaManagementComponent implements OnInit {

  areas: AreaModel[];
  showForm: boolean;
  areaForm: FormGroup;

  constructor(private service: KmService) { }

  ngOnInit(): void {
    this.getAreas();
    this.areaForm = new FormGroup({
      id: new FormControl('', Validators.required),
      area: new FormControl('', Validators.required),
      description: new FormControl('')
    });
  }

  getAreas(): void{
    this.service.getAreas("active").subscribe(
      area => {
        this.areas = area;
        console.log(this.areas);
      }
    );
  }

  getForm(){
    this.showForm = !this.showForm;
  }

  getUpdate(a: AreaModel){
    this.areaForm.controls.id.patchValue(a.id);
    this.areaForm.controls.area.patchValue(a.area);
    this.areaForm.controls.description.patchValue(a.description);
    this.showForm = true;
  }

  submit(){
    let id = this.areaForm.controls.id.value;

    this.service.updateArea(id, this.areaForm.value).subscribe(
      up => {
        this.updateInArray(id, up);
        this.getForm();
      }, e => {
        console.log(e);
      }
    );
  }

  updateInArray(id: number, area: AreaModel): void{
    this.areas.find(el => {
      if(Number(el.id) == id){
        el.area = area.area;
        el.description = area.description;
      }
    });
  }

  async activate(id: any, activation: number): Promise<void> {
    let body: any = [];
    try{
      if (activation == 1){
        body.push({"archive": 0});
        const res = await this.service.activateArea(id, JSON.stringify(body).replace('[','').replace(']',''));
        console.log(res);
        this.getAreas();
      }else{
        body.push({"archive": 1});
        const res = await this.service.activateArea(id, JSON.stringify(body).replace('[','').replace(']',''));
        console.log(res);
        this.getAreas();
      }
    }catch(e){
      console.log(e);
    }
  }

}
