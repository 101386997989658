<form [formGroup]="form"
(ngSubmit)="addArea()">
    <mat-card>
        <mat-form-field>
            <mat-label>Area</mat-label>
            <input matInput placeholder="Nome da área" formControlName="area">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Descrição</mat-label>
            <textarea matInput  placeholder="Descrição" formControlName="description"></textarea>
          </mat-form-field>

        <div style="text-align: center;">
            <button mat-raised-button color="accent" [disabled]="!form.valid">Cadastrar</button>
        </div>
    </mat-card>
</form>