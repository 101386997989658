<form [formGroup]="form"
(ngSubmit)="addKnowledge()">
    <mat-card>
        <mat-form-field>
            <mat-label>Conhecimento</mat-label>
            <input matInput placeholder="Nome da área" formControlName="knowledge">
        </mat-form-field>

        <div style="text-align: center;">
            <button mat-raised-button color="accent" [disabled]="!form.valid">Cadastrar</button>
        </div>
    </mat-card>
</form>