import { Component, OnInit } from '@angular/core';
import { KmService } from '../km.service';

@Component({
  selector: 'km-performance-test',
  templateUrl: './performance-test.component.html',
  styleUrls: ['./performance-test.component.css']
})
export class PerformanceTestComponent implements OnInit {

  constructor(private service: KmService) { }

  loader = false;
  areas: any = [];
  array: any = [];

  ngOnInit(): void {
    this.get();
    this.getNewProcedureAreas();
  }

  get(): void{
    this.loader = true;
    this.service.getAreas("").subscribe(
      area => {
        this.areas = area;
        this.loader = false;
      },e=> {
        this.loader = false;
      }
    );
  }

  getNewProcedureAreas() {
    this.service.getNewAreasList().subscribe(
      a => {
        console.log(a);
        this.array = a;
      }
    );
  }

}
