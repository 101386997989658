<form [formGroup]="form"
(ngSubmit)="addAssunto()">
    <mat-card>
        <mat-form-field>
            <mat-label>Assunto</mat-label>
            <input matInput placeholder="Nome do assunto" formControlName="subject">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Descrição</mat-label>
            <textarea matInput  placeholder="Descrição" formControlName="description"></textarea>
        </mat-form-field>

        <div style="text-align: center;">
            <button mat-raised-button color="accent" [disabled]="!form.valid">Cadastrar</button>
        </div>
    </mat-card>
</form>