<br>
<br>

<form style="display: flex; flex-direction: column"
      [formGroup]="form"
      (ngSubmit)="submit()">
  <div>
    Área: <input type="text" value="{{area.area}}" formControlName="area">
  </div>
  <div>
    Descrição <input type="text" value="{{area.description}}" formControlName="description">
  </div>
  <button>Atualizar</button>
</form>
