import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';
import { AreaModel } from './models/Area';

@Injectable({
  providedIn: 'root'
})
export class KmService {

  constructor(private http: HttpClient) { }

  areas = `${environment.API}area/`;
  assuntos = `${environment.API}assunto/`;
  conhecimento = `${environment.API}conhecimento/`;
  registro = `${environment.API}registro/`;

  getAreas(active: string): Observable<AreaModel[]> {

    const headers = new HttpHeaders({ 'Content-Type': 'text/html; charset=UTF-8' });

    return this.http.get<AreaModel[]>(this.areas + active, { headers, responseType: 'json' });
  }

  getAreaById(id: any): Observable<any> {

    const headers = new HttpHeaders({ 'Content-Type': 'text/html; charset=UTF-8' });

    return this.http.get(this.areas + id, { headers });
  }

  updateArea(id: number, body: any): Observable<AreaModel> {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put<AreaModel>(this.areas + id, body, { headers });
  }

  // tslint:disable-next-line:typedef
  async activateArea(id: any, body: any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.patch(this.areas + id, body, { headers, observe: 'response'}).toPromise();
  }

  getSubject(id: string): Observable<any> {
    // const params = new HttpParams().set('id' , id);
    // const headers = new HttpHeaders({ 'Content-Type': 'text/html; charset=UTF-8' });

    return this.http.get(this.assuntos + 'area/' + id );
  }

  getAssuntos(): Observable<any> {
    // const headers = new HttpHeaders({ 'Content-Type': 'text/html; charset=UTF-8' });

    return this.http.get(this.assuntos);
  }

  getAssuntoById(id: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'text/html; charset=UTF-8' });

    return this.http.get(this.assuntos + id, { headers });
  }

  getConhecimentoById(id: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(this.conhecimento + 'assunto/' + id, { headers });
  }

  getRegistroById(id: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'text/html; charset=UTF-8' });
    return this.http.get(this.registro + 'conhecimento/' + id, { headers });
  }

  async updateRegistro(id: any, body: any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.patch(this.registro + id, body, { headers, observe: 'response'}).toPromise();
  }

  async addArea(body: any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(this.areas, body, { headers, observe: 'response'}).toPromise();
  }

  async addAssunto(body: any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(this.assuntos, body, { headers, observe: 'response'}).toPromise();
  }

  async addConhecimento(body: any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(this.conhecimento, body, { headers, observe: 'response'}).toPromise();
  }

  async addRegistro(body: any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(this.registro, body, { headers, observe: 'response'}).toPromise();
  }

  getNewAreasList(): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(this.areas + "go", { headers });
  }
}
