import {Component, ElementRef, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { DataService } from '../data.service';
import {KmService} from '../km.service';

@Component({
  selector: 'km-assuntos',
  templateUrl: './assuntos.component.html',
  styleUrls: ['./assuntos.component.css']
})
export class AssuntosComponent implements OnInit {

  constructor(
    private service: KmService,
    private dataService: DataService
  ) { }

  @ViewChildren('icons') icons: QueryList<MatIcon>;
  assuntos: any = [];
  @Input() visible: boolean;
  @Input() idArea: any;
  todos: any = [];
  unico: any = [];
  showKnowledge = false;
  knowledgeId: number;

  visibleIndices = new Set<number>();

  showForm: boolean;
  idSubject: number;
  assuntoVisible: boolean;

  ngOnInit(): void {
    this.getAssuntos();
    this.dataService.visibility.subscribe(
      visible => {
        this.assuntoVisible = visible;
      }
    );
  }

  getIcon(id: number): void{
    let icon = '';
    this.icons.forEach(e => {
      if (e._elementRef.nativeElement.id == id){
        icon = e._elementRef.nativeElement.innerText;
          if (icon.includes('right')){
            e._elementRef.nativeElement.innerText = 'expand_more'
          }else{
            e._elementRef.nativeElement.innerText = 'chevron_right';
          }
      }
    });
  }

  addConhecimento(success: boolean): void{
    if (success){
      this.showForm = false;
      this.getAssuntos();
    }
  }

  showConhecimento(index: any): void {
    if (!this.visibleIndices.delete(index)) {
      this.visibleIndices.add(index);
    }
  }

  show(){
    this.showKnowledge = !this.showKnowledge;
  }

  showFormConhecimento(id: number){
    this.idSubject = id;
    this.showForm = !this.showForm;
  }

  getKnowledge(id: number){
    this.show();
    this.knowledgeId = id;
    
    console.log(id)
  }

  getAssuntos(): void {
    this.service.getSubject(this.idArea).subscribe(
      assuntos => {
        this.unico = assuntos;
      }, e => {
        console.log(e);
      }
    );
  }

  getAll(): void{
    this.service.getAssuntos().subscribe(
      all => {
        console.log(all);
        this.todos = all;
      }
    );
  }

}
