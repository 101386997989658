<section class="toolbar">
    <button mat-raised-button color="accent">Nova Área</button>
</section>

<section *ngIf="showForm" class="area-item">
  <mat-card>
    <form [formGroup]="areaForm" (ngSubmit)="submit()">
        <input type="hidden" name="id" formControlName="id">
      <mat-form-field >
        <mat-label>Area</mat-label>
        <input matInput placeholder="Area" formControlName="area">
      </mat-form-field>
<!--        <input matInput type="text" name="area" formControlName="area"> <br>-->
        <input type="text" name="description" formControlName="description"> <br>
        <button type="submit"><i class="fas fa-sync-alt teal"></i> Atualizar</button>
    </form>
    <button mat-raised-button color="accent" (click)="getForm()">Cancelar</button>
  </mat-card>
</section>

<section class="lista" *ngIf="!showForm">
  <mat-card *ngFor="let a of areas" style="max-width: 400px; width: 90%; margin: 0 auto 10px;" class="mat-elevation-z1">

    <div class="" >
            <br>{{a.area}}
            <br> {{a.description}}
            <br> {{a.createdAt | date:"dd/MM/yyyy hh:mm"}} - {{a.lastUpdate | date:"dd/MM/yyyy hh:mm"}}
            <br>
            <i class="fas {{a.archive == 1 ? 'fa-times cancel' : 'fa-check check'}}"></i>
            <br>
            <div class="footer">
                <button mat-raised-button color="accent" (click)="getUpdate(a)">Atualizar</button>
                <button mat-raised-button color="warn" style="margin-left: auto;" (click)="activate(a.id, a.archive)">{{a.archive == 1 ? 'Ativar' : 'Desativar'}}</button>
            </div>

    </div>
  </mat-card>
</section>

<section class="cadastro">

</section>
