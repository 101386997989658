import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

  // .
  private visible = new BehaviorSubject<boolean>(true);
  visibility = this.visible.asObservable();

  changeVisibility(visible: boolean): void{
    this.visible.next(visible);
  }
}
