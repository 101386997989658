<ng-container *ngIf="!registro">
    <mat-list style="max-width: 400px; width: 95%; margin: 0 auto;">
        <!-- <div mat-subheader>Conhecimento:</div> -->
        <mat-list-item *ngFor="let k of conhecimento" style="color: teal; padding: 5px; font-weight: 500;">
            <mat-icon (click)="getRegistro(k.id,k.knowledge )" mat-list-icon>folder</mat-icon>
            <div mat-line>{{k.knowledge}}</div>
            <mat-divider></mat-divider>
        </mat-list-item>            
    </mat-list>
</ng-container>

<div *ngIf="registro">
    <km-registro *ngIf="registro" 
        [idConhecimento]="idConhecimento" 
        [conhecimento]="titulo"
        (visible)="hideRegistro($event)">
    </km-registro>
</div>
    