import {HttpErrorResponse} from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { LoginService } from './login.service';


@Injectable()

export class ApplicationErrorHandler extends ErrorHandler{
    constructor(
        private login: LoginService,
        private zone: NgZone
        ){
        super();
    }
    handleError(errorResponse: HttpErrorResponse | any){
        // console.log(errorResponse);
        this.zone.run(() => {
            if (errorResponse instanceof HttpErrorResponse) {
               console.log('Erro customizado' , errorResponse.status);
               this.login.renew();
            }
            else if (errorResponse.message == 'cep_invalido') {
                // this.ns.notify("CEP Inválido!");
            }
        });
            
    }
}
